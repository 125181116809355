import validate from "/tmp/build_f44ac0e6/node_modules/nuxt/dist/pages/runtime/validate.js";
import auth_45global from "/tmp/build_f44ac0e6/middleware/auth.global.ts";
import feature_45redirections_45global from "/tmp/build_f44ac0e6/middleware/feature.redirections.global.js";
import sentry_45global from "/tmp/build_f44ac0e6/middleware/sentry.global.ts";
import manifest_45route_45rule from "/tmp/build_f44ac0e6/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  auth_45global,
  feature_45redirections_45global,
  sentry_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  admin: () => import("/tmp/build_f44ac0e6/middleware/admin.ts"),
  validator: () => import("/tmp/build_f44ac0e6/middleware/validator.ts")
}