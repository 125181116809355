import { default as adminswe7Tyihw86Meta } from "/tmp/build_f44ac0e6/pages/administration/admins.vue?macro=true";
import { default as _91id_93hbtRseOS4MMeta } from "/tmp/build_f44ac0e6/pages/administration/billing/[id].vue?macro=true";
import { default as indexEi8FcDfAmDMeta } from "/tmp/build_f44ac0e6/pages/administration/billing/index.vue?macro=true";
import { default as bookingsc9S7ZjBOXMMeta } from "/tmp/build_f44ac0e6/pages/administration/bookings.vue?macro=true";
import { default as ecostatisticseAKPyf3pWrMeta } from "/tmp/build_f44ac0e6/pages/administration/ecostatistics.vue?macro=true";
import { default as exportstab1xvE93pMeta } from "/tmp/build_f44ac0e6/pages/administration/exports.vue?macro=true";
import { default as fidelityz4uaEMY4lYMeta } from "/tmp/build_f44ac0e6/pages/administration/fidelity.vue?macro=true";
import { default as invoices7jM3qqgO8NMeta } from "/tmp/build_f44ac0e6/pages/administration/invoices.vue?macro=true";
import { default as policyOrWEMXfU0VMeta } from "/tmp/build_f44ac0e6/pages/administration/organization/policy.vue?macro=true";
import { default as _91id_93Yqnqeumb7GMeta } from "/tmp/build_f44ac0e6/pages/administration/payment/[id].vue?macro=true";
import { default as indexvXOXZVu1q7Meta } from "/tmp/build_f44ac0e6/pages/administration/payment/index.vue?macro=true";
import { default as statisticsto1VgFBO95Meta } from "/tmp/build_f44ac0e6/pages/administration/statistics.vue?macro=true";
import { default as indexYONxJyrwGUMeta } from "/tmp/build_f44ac0e6/pages/administration/teams/[teamId]/index.vue?macro=true";
import { default as invitation8VIhIsQywiMeta } from "/tmp/build_f44ac0e6/pages/administration/teams/[teamId]/invitation.vue?macro=true";
import { default as index4IeGBiuzSgMeta } from "/tmp/build_f44ac0e6/pages/administration/teams/[teamId]/policy/index.vue?macro=true";
import { default as modifyO59FSigDvrMeta } from "/tmp/build_f44ac0e6/pages/administration/teams/[teamId]/policy/modify.vue?macro=true";
import { default as validatorsyr9qMuI6i3Meta } from "/tmp/build_f44ac0e6/pages/administration/teams/[teamId]/validators.vue?macro=true";
import { default as _91teamId_93JCcy7hjTDqMeta } from "/tmp/build_f44ac0e6/pages/administration/teams/[teamId].vue?macro=true";
import { default as indexMr4Gjd2EUgMeta } from "/tmp/build_f44ac0e6/pages/administration/teams/index.vue?macro=true";
import { default as trip_45labelsqMnaiAphwNMeta } from "/tmp/build_f44ac0e6/pages/administration/trip-labels.vue?macro=true";
import { default as administrationzuImdH2c0VMeta } from "/tmp/build_f44ac0e6/pages/administration.vue?macro=true";
import { default as swile7CdyTYLiuaMeta } from "/tmp/build_f44ac0e6/pages/auth/swile.vue?macro=true";
import { default as index8AwhIQgeb3Meta } from "/tmp/build_f44ac0e6/pages/bookings/index.vue?macro=true";
import { default as indexwFxxY7F3ENMeta } from "/tmp/build_f44ac0e6/pages/bookings/validator/index.vue?macro=true";
import { default as pendingdqcX0eXMWRMeta } from "/tmp/build_f44ac0e6/pages/bookings/validator/pending.vue?macro=true";
import { default as _91id_9375o6fxdSD6Meta } from "/tmp/build_f44ac0e6/pages/car/[id].vue?macro=true";
import { default as fidelitysVFB2tLy0XMeta } from "/tmp/build_f44ac0e6/pages/fidelity.vue?macro=true";
import { default as exchangelHfwNEDbWZMeta } from "/tmp/build_f44ac0e6/pages/flight/[id]/exchange.vue?macro=true";
import { default as faresPMEvZX0dlsMeta } from "/tmp/build_f44ac0e6/pages/flight/[id]/fares.vue?macro=true";
import { default as indexATqrZ1BtKmMeta } from "/tmp/build_f44ac0e6/pages/flight/[id]/index.vue?macro=true";
import { default as forgotREl6Ul8LbdMeta } from "/tmp/build_f44ac0e6/pages/forgot.vue?macro=true";
import { default as get_45swile_45apppF6Oyt1fMwMeta } from "/tmp/build_f44ac0e6/pages/get-swile-app.vue?macro=true";
import { default as helpWrUMz5AqHyMeta } from "/tmp/build_f44ac0e6/pages/help.vue?macro=true";
import { default as _91id_936tdAi4rzkKMeta } from "/tmp/build_f44ac0e6/pages/hotel/[id].vue?macro=true";
import { default as _91id_9384CyOCreKLMeta } from "/tmp/build_f44ac0e6/pages/impersonate/[id].vue?macro=true";
import { default as indexdouwGktaXIMeta } from "/tmp/build_f44ac0e6/pages/index.vue?macro=true";
import { default as _91suffix_93Dh5AGlEwppMeta } from "/tmp/build_f44ac0e6/pages/invitation/[suffix].vue?macro=true";
import { default as _91token_935bk4wSjXcWMeta } from "/tmp/build_f44ac0e6/pages/invite/[token].vue?macro=true";
import { default as _91id_93roqu5Ya8NdMeta } from "/tmp/build_f44ac0e6/pages/journeys/[id].vue?macro=true";
import { default as indexrZHcl70YQFMeta } from "/tmp/build_f44ac0e6/pages/journeys/index.vue?macro=true";
import { default as membersFwcpsolKflMeta } from "/tmp/build_f44ac0e6/pages/myteam/members.vue?macro=true";
import { default as policyOJM0Cg36eKMeta } from "/tmp/build_f44ac0e6/pages/myteam/policy.vue?macro=true";
import { default as validatorsEPwLyHQEQ9Meta } from "/tmp/build_f44ac0e6/pages/myteam/validators.vue?macro=true";
import { default as myteamTiqLk0OiY1Meta } from "/tmp/build_f44ac0e6/pages/myteam.vue?macro=true";
import { default as new_45userFoYfKadLkHMeta } from "/tmp/build_f44ac0e6/pages/new-user.vue?macro=true";
import { default as passwordxHnQvAoh4FMeta } from "/tmp/build_f44ac0e6/pages/password.vue?macro=true";
import { default as indexbGaBKUacfeMeta } from "/tmp/build_f44ac0e6/pages/search/car/[search_id]/index.vue?macro=true";
import { default as indexTY5jPObzaAMeta } from "/tmp/build_f44ac0e6/pages/search/car/index.vue?macro=true";
import { default as _91search_id_93qtnM0ItTeHMeta } from "/tmp/build_f44ac0e6/pages/search/car/init/[search_id].vue?macro=true";
import { default as _91search_id_93wuLlfjGKyMMeta } from "/tmp/build_f44ac0e6/pages/search/flight/[search_id].vue?macro=true";
import { default as indexaOrsGFz828Meta } from "/tmp/build_f44ac0e6/pages/search/flight/index.vue?macro=true";
import { default as indexBdVkzb2khUMeta } from "/tmp/build_f44ac0e6/pages/search/hotel/[search_id]/index.vue?macro=true";
import { default as _91hotelIds_9317rjuhEYZXMeta } from "/tmp/build_f44ac0e6/pages/search/hotel/[search_id]/rooms/[hotelIds].vue?macro=true";
import { default as indexNc7sGVVTwwMeta } from "/tmp/build_f44ac0e6/pages/search/hotel/index.vue?macro=true";
import { default as _91search_id_93q4VCMW6QSCMeta } from "/tmp/build_f44ac0e6/pages/search/train/[search_id].vue?macro=true";
import { default as indexXwZalfnjw0Meta } from "/tmp/build_f44ac0e6/pages/search/train/index.vue?macro=true";
import { default as searchMKpNNaCyfSMeta } from "/tmp/build_f44ac0e6/pages/search.vue?macro=true";
import { default as indexSHpMOHPt2pMeta } from "/tmp/build_f44ac0e6/pages/teams/[teamId]/index.vue?macro=true";
import { default as invitationpcLV9onAwGMeta } from "/tmp/build_f44ac0e6/pages/teams/[teamId]/invitation.vue?macro=true";
import { default as policyT6NUbsCZivMeta } from "/tmp/build_f44ac0e6/pages/teams/[teamId]/policy.vue?macro=true";
import { default as validatorsegr9ytnLtVMeta } from "/tmp/build_f44ac0e6/pages/teams/[teamId]/validators.vue?macro=true";
import { default as _91teamId_93xTf5pQpJf2Meta } from "/tmp/build_f44ac0e6/pages/teams/[teamId].vue?macro=true";
import { default as indexqFBPRiN3gKMeta } from "/tmp/build_f44ac0e6/pages/teams/index.vue?macro=true";
import { default as teamsPHHACy0g29Meta } from "/tmp/build_f44ac0e6/pages/teams.vue?macro=true";
import { default as indexmlSPTGjaRUMeta } from "/tmp/build_f44ac0e6/pages/train/[id]/cancel/index.vue?macro=true";
import { default as indexVRsC1zInczMeta } from "/tmp/build_f44ac0e6/pages/train/[id]/exchange/changeable-selection/index.vue?macro=true";
import { default as indexddUn4DtrEkMeta } from "/tmp/build_f44ac0e6/pages/train/[id]/exchange/section-selection/index.vue?macro=true";
import { default as indexv4XDrBH3EtMeta } from "/tmp/build_f44ac0e6/pages/train/[id]/exchange/train-selection/index.vue?macro=true";
import { default as indexuhh1pOJGl3Meta } from "/tmp/build_f44ac0e6/pages/train/[id]/index.vue?macro=true";
import { default as driver_45licensexQVTdRybBnMeta } from "/tmp/build_f44ac0e6/pages/users/[id]/driver-license.vue?macro=true";
import { default as fidelitybEoKnmbCl9Meta } from "/tmp/build_f44ac0e6/pages/users/[id]/fidelity.vue?macro=true";
import { default as indexlWPssj8zZTMeta } from "/tmp/build_f44ac0e6/pages/users/[id]/index.vue?macro=true";
import { default as passportkAFkGiOktHMeta } from "/tmp/build_f44ac0e6/pages/users/[id]/passport.vue?macro=true";
import { default as passwordze729hXUT8Meta } from "/tmp/build_f44ac0e6/pages/users/[id]/password.vue?macro=true";
import { default as _91id_93vtKgzfdaYkMeta } from "/tmp/build_f44ac0e6/pages/users/[id].vue?macro=true";
import { default as indexPEQ1aAcrdnMeta } from "/tmp/build_f44ac0e6/pages/users/index.vue?macro=true";
import { default as vouchers9nlYMJp62MMeta } from "/tmp/build_f44ac0e6/pages/vouchers.vue?macro=true";
import { default as component_45stubzIw5vSZPrZMeta } from "/tmp/build_f44ac0e6/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubzIw5vSZPrZ } from "/tmp/build_f44ac0e6/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "administration",
    path: "/administration",
    meta: administrationzuImdH2c0VMeta || {},
    component: () => import("/tmp/build_f44ac0e6/pages/administration.vue").then(m => m.default || m),
    children: [
  {
    name: "administration-admins",
    path: "admins",
    component: () => import("/tmp/build_f44ac0e6/pages/administration/admins.vue").then(m => m.default || m)
  },
  {
    name: "administration-billing-id",
    path: "billing/:id()",
    component: () => import("/tmp/build_f44ac0e6/pages/administration/billing/[id].vue").then(m => m.default || m)
  },
  {
    name: "administration-billing",
    path: "billing",
    component: () => import("/tmp/build_f44ac0e6/pages/administration/billing/index.vue").then(m => m.default || m)
  },
  {
    name: "administration-bookings",
    path: "bookings",
    component: () => import("/tmp/build_f44ac0e6/pages/administration/bookings.vue").then(m => m.default || m)
  },
  {
    name: "administration-ecostatistics",
    path: "ecostatistics",
    component: () => import("/tmp/build_f44ac0e6/pages/administration/ecostatistics.vue").then(m => m.default || m)
  },
  {
    name: "administration-exports",
    path: "exports",
    component: () => import("/tmp/build_f44ac0e6/pages/administration/exports.vue").then(m => m.default || m)
  },
  {
    name: "administration-fidelity",
    path: "fidelity",
    component: () => import("/tmp/build_f44ac0e6/pages/administration/fidelity.vue").then(m => m.default || m)
  },
  {
    name: "administration-invoices",
    path: "invoices",
    component: () => import("/tmp/build_f44ac0e6/pages/administration/invoices.vue").then(m => m.default || m)
  },
  {
    name: "administration-organization-policy",
    path: "organization/policy",
    component: () => import("/tmp/build_f44ac0e6/pages/administration/organization/policy.vue").then(m => m.default || m)
  },
  {
    name: "administration-payment-id",
    path: "payment/:id()",
    component: () => import("/tmp/build_f44ac0e6/pages/administration/payment/[id].vue").then(m => m.default || m)
  },
  {
    name: "administration-payment",
    path: "payment",
    component: () => import("/tmp/build_f44ac0e6/pages/administration/payment/index.vue").then(m => m.default || m)
  },
  {
    name: "administration-statistics",
    path: "statistics",
    component: () => import("/tmp/build_f44ac0e6/pages/administration/statistics.vue").then(m => m.default || m)
  },
  {
    name: _91teamId_93JCcy7hjTDqMeta?.name,
    path: "teams/:teamId()",
    component: () => import("/tmp/build_f44ac0e6/pages/administration/teams/[teamId].vue").then(m => m.default || m),
    children: [
  {
    name: "administration-teams-teamId",
    path: "",
    meta: indexYONxJyrwGUMeta || {},
    component: () => import("/tmp/build_f44ac0e6/pages/administration/teams/[teamId]/index.vue").then(m => m.default || m)
  },
  {
    name: "administration-teams-teamId-invitation",
    path: "invitation",
    component: () => import("/tmp/build_f44ac0e6/pages/administration/teams/[teamId]/invitation.vue").then(m => m.default || m)
  },
  {
    name: "administration-teams-teamId-policy",
    path: "policy",
    component: () => import("/tmp/build_f44ac0e6/pages/administration/teams/[teamId]/policy/index.vue").then(m => m.default || m)
  },
  {
    name: "administration-teams-teamId-policy-modify",
    path: "policy/modify",
    meta: modifyO59FSigDvrMeta || {},
    component: () => import("/tmp/build_f44ac0e6/pages/administration/teams/[teamId]/policy/modify.vue").then(m => m.default || m)
  },
  {
    name: "administration-teams-teamId-validators",
    path: "validators",
    component: () => import("/tmp/build_f44ac0e6/pages/administration/teams/[teamId]/validators.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "administration-teams",
    path: "teams",
    component: () => import("/tmp/build_f44ac0e6/pages/administration/teams/index.vue").then(m => m.default || m)
  },
  {
    name: "administration-trip-labels",
    path: "trip-labels",
    component: () => import("/tmp/build_f44ac0e6/pages/administration/trip-labels.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "auth-swile",
    path: "/auth/swile",
    meta: swile7CdyTYLiuaMeta || {},
    component: () => import("/tmp/build_f44ac0e6/pages/auth/swile.vue").then(m => m.default || m)
  },
  {
    name: "bookings",
    path: "/bookings",
    component: () => import("/tmp/build_f44ac0e6/pages/bookings/index.vue").then(m => m.default || m)
  },
  {
    name: "bookings-validator",
    path: "/bookings/validator",
    meta: indexwFxxY7F3ENMeta || {},
    component: () => import("/tmp/build_f44ac0e6/pages/bookings/validator/index.vue").then(m => m.default || m)
  },
  {
    name: "bookings-validator-pending",
    path: "/bookings/validator/pending",
    meta: pendingdqcX0eXMWRMeta || {},
    component: () => import("/tmp/build_f44ac0e6/pages/bookings/validator/pending.vue").then(m => m.default || m)
  },
  {
    name: "car-id",
    path: "/car/:id()",
    component: () => import("/tmp/build_f44ac0e6/pages/car/[id].vue").then(m => m.default || m)
  },
  {
    name: "fidelity",
    path: "/fidelity",
    component: () => import("/tmp/build_f44ac0e6/pages/fidelity.vue").then(m => m.default || m)
  },
  {
    name: "flight-id-exchange",
    path: "/flight/:id()/exchange",
    component: () => import("/tmp/build_f44ac0e6/pages/flight/[id]/exchange.vue").then(m => m.default || m)
  },
  {
    name: "flight-id-fares",
    path: "/flight/:id()/fares",
    component: () => import("/tmp/build_f44ac0e6/pages/flight/[id]/fares.vue").then(m => m.default || m)
  },
  {
    name: "flight-id",
    path: "/flight/:id()",
    component: () => import("/tmp/build_f44ac0e6/pages/flight/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "forgot",
    path: "/forgot",
    meta: forgotREl6Ul8LbdMeta || {},
    component: () => import("/tmp/build_f44ac0e6/pages/forgot.vue").then(m => m.default || m)
  },
  {
    name: "get-swile-app",
    path: "/get-swile-app",
    meta: get_45swile_45apppF6Oyt1fMwMeta || {},
    component: () => import("/tmp/build_f44ac0e6/pages/get-swile-app.vue").then(m => m.default || m)
  },
  {
    name: "help",
    path: "/help",
    component: () => import("/tmp/build_f44ac0e6/pages/help.vue").then(m => m.default || m)
  },
  {
    name: "hotel-id",
    path: "/hotel/:id()",
    component: () => import("/tmp/build_f44ac0e6/pages/hotel/[id].vue").then(m => m.default || m)
  },
  {
    name: "impersonate-id",
    path: "/impersonate/:id()",
    component: () => import("/tmp/build_f44ac0e6/pages/impersonate/[id].vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    meta: indexdouwGktaXIMeta || {},
    component: () => import("/tmp/build_f44ac0e6/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "invitation-suffix",
    path: "/invitation/:suffix()",
    meta: _91suffix_93Dh5AGlEwppMeta || {},
    component: () => import("/tmp/build_f44ac0e6/pages/invitation/[suffix].vue").then(m => m.default || m)
  },
  {
    name: "invite-token",
    path: "/invite/:token()",
    meta: _91token_935bk4wSjXcWMeta || {},
    component: () => import("/tmp/build_f44ac0e6/pages/invite/[token].vue").then(m => m.default || m)
  },
  {
    name: "journeys-id",
    path: "/journeys/:id()",
    component: () => import("/tmp/build_f44ac0e6/pages/journeys/[id].vue").then(m => m.default || m)
  },
  {
    name: "journeys",
    path: "/journeys",
    component: () => import("/tmp/build_f44ac0e6/pages/journeys/index.vue").then(m => m.default || m)
  },
  {
    name: "myteam",
    path: "/myteam",
    component: () => import("/tmp/build_f44ac0e6/pages/myteam.vue").then(m => m.default || m),
    children: [
  {
    name: "myteam-members",
    path: "members",
    component: () => import("/tmp/build_f44ac0e6/pages/myteam/members.vue").then(m => m.default || m)
  },
  {
    name: "myteam-policy",
    path: "policy",
    component: () => import("/tmp/build_f44ac0e6/pages/myteam/policy.vue").then(m => m.default || m)
  },
  {
    name: "myteam-validators",
    path: "validators",
    component: () => import("/tmp/build_f44ac0e6/pages/myteam/validators.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "new-user",
    path: "/new-user",
    meta: new_45userFoYfKadLkHMeta || {},
    component: () => import("/tmp/build_f44ac0e6/pages/new-user.vue").then(m => m.default || m)
  },
  {
    name: "password",
    path: "/password",
    meta: passwordxHnQvAoh4FMeta || {},
    component: () => import("/tmp/build_f44ac0e6/pages/password.vue").then(m => m.default || m)
  },
  {
    name: "search",
    path: "/search",
    component: () => import("/tmp/build_f44ac0e6/pages/search.vue").then(m => m.default || m),
    children: [
  {
    name: "search-car-search_id",
    path: "car/:search_id()",
    component: () => import("/tmp/build_f44ac0e6/pages/search/car/[search_id]/index.vue").then(m => m.default || m)
  },
  {
    name: "search-car",
    path: "car",
    component: () => import("/tmp/build_f44ac0e6/pages/search/car/index.vue").then(m => m.default || m)
  },
  {
    name: "search-car-init-search_id",
    path: "car/init/:search_id()",
    component: () => import("/tmp/build_f44ac0e6/pages/search/car/init/[search_id].vue").then(m => m.default || m)
  },
  {
    name: "search-flight-search_id",
    path: "flight/:search_id()",
    component: () => import("/tmp/build_f44ac0e6/pages/search/flight/[search_id].vue").then(m => m.default || m)
  },
  {
    name: "search-flight",
    path: "flight",
    component: () => import("/tmp/build_f44ac0e6/pages/search/flight/index.vue").then(m => m.default || m)
  },
  {
    name: "search-hotel-search_id",
    path: "hotel/:search_id()",
    component: () => import("/tmp/build_f44ac0e6/pages/search/hotel/[search_id]/index.vue").then(m => m.default || m)
  },
  {
    name: "search-hotel-search_id-rooms-hotelIds",
    path: "hotel/:search_id()/rooms/:hotelIds()",
    component: () => import("/tmp/build_f44ac0e6/pages/search/hotel/[search_id]/rooms/[hotelIds].vue").then(m => m.default || m)
  },
  {
    name: "search-hotel",
    path: "hotel",
    component: () => import("/tmp/build_f44ac0e6/pages/search/hotel/index.vue").then(m => m.default || m)
  },
  {
    name: "search-train-search_id",
    path: "train/:search_id()",
    component: () => import("/tmp/build_f44ac0e6/pages/search/train/[search_id].vue").then(m => m.default || m)
  },
  {
    name: "search-train",
    path: "train",
    component: () => import("/tmp/build_f44ac0e6/pages/search/train/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: teamsPHHACy0g29Meta?.name,
    path: "/teams",
    meta: teamsPHHACy0g29Meta || {},
    component: () => import("/tmp/build_f44ac0e6/pages/teams.vue").then(m => m.default || m),
    children: [
  {
    name: _91teamId_93xTf5pQpJf2Meta?.name,
    path: ":teamId()",
    component: () => import("/tmp/build_f44ac0e6/pages/teams/[teamId].vue").then(m => m.default || m),
    children: [
  {
    name: "teams-teamId",
    path: "",
    component: () => import("/tmp/build_f44ac0e6/pages/teams/[teamId]/index.vue").then(m => m.default || m)
  },
  {
    name: "teams-teamId-invitation",
    path: "invitation",
    component: () => import("/tmp/build_f44ac0e6/pages/teams/[teamId]/invitation.vue").then(m => m.default || m)
  },
  {
    name: "teams-teamId-policy",
    path: "policy",
    component: () => import("/tmp/build_f44ac0e6/pages/teams/[teamId]/policy.vue").then(m => m.default || m)
  },
  {
    name: "teams-teamId-validators",
    path: "validators",
    component: () => import("/tmp/build_f44ac0e6/pages/teams/[teamId]/validators.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "teams",
    path: "",
    component: () => import("/tmp/build_f44ac0e6/pages/teams/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "train-id-cancel",
    path: "/train/:id()/cancel",
    component: () => import("/tmp/build_f44ac0e6/pages/train/[id]/cancel/index.vue").then(m => m.default || m)
  },
  {
    name: "train-id-exchange-changeable-selection",
    path: "/train/:id()/exchange/changeable-selection",
    component: () => import("/tmp/build_f44ac0e6/pages/train/[id]/exchange/changeable-selection/index.vue").then(m => m.default || m)
  },
  {
    name: "train-id-exchange-section-selection",
    path: "/train/:id()/exchange/section-selection",
    component: () => import("/tmp/build_f44ac0e6/pages/train/[id]/exchange/section-selection/index.vue").then(m => m.default || m)
  },
  {
    name: "train-id-exchange-train-selection",
    path: "/train/:id()/exchange/train-selection",
    component: () => import("/tmp/build_f44ac0e6/pages/train/[id]/exchange/train-selection/index.vue").then(m => m.default || m)
  },
  {
    name: "train-id",
    path: "/train/:id()",
    component: () => import("/tmp/build_f44ac0e6/pages/train/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93vtKgzfdaYkMeta?.name,
    path: "/users/:id()",
    component: () => import("/tmp/build_f44ac0e6/pages/users/[id].vue").then(m => m.default || m),
    children: [
  {
    name: "users-id-driver-license",
    path: "driver-license",
    component: () => import("/tmp/build_f44ac0e6/pages/users/[id]/driver-license.vue").then(m => m.default || m)
  },
  {
    name: "users-id-fidelity",
    path: "fidelity",
    component: () => import("/tmp/build_f44ac0e6/pages/users/[id]/fidelity.vue").then(m => m.default || m)
  },
  {
    name: "users-id",
    path: "",
    component: () => import("/tmp/build_f44ac0e6/pages/users/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "users-id-passport",
    path: "passport",
    component: () => import("/tmp/build_f44ac0e6/pages/users/[id]/passport.vue").then(m => m.default || m)
  },
  {
    name: "users-id-password",
    path: "password",
    component: () => import("/tmp/build_f44ac0e6/pages/users/[id]/password.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "users",
    path: "/users",
    component: () => import("/tmp/build_f44ac0e6/pages/users/index.vue").then(m => m.default || m)
  },
  {
    name: "vouchers",
    path: "/vouchers",
    component: () => import("/tmp/build_f44ac0e6/pages/vouchers.vue").then(m => m.default || m)
  },
  {
    name: "teams-vouchers",
    path: "/teams-vouchers",
    component: () => import("/tmp/build_f44ac0e6/pages/vouchers.vue").then(m => m.default || m)
  },
  {
    name: component_45stubzIw5vSZPrZMeta?.name,
    path: "/metrics",
    component: component_45stubzIw5vSZPrZ
  },
  {
    name: component_45stubzIw5vSZPrZMeta?.name,
    path: "/en",
    component: component_45stubzIw5vSZPrZ
  },
  {
    name: component_45stubzIw5vSZPrZMeta?.name,
    path: "/fr",
    component: component_45stubzIw5vSZPrZ
  },
  {
    name: component_45stubzIw5vSZPrZMeta?.name,
    path: "/pt-BR",
    component: component_45stubzIw5vSZPrZ
  },
  {
    name: component_45stubzIw5vSZPrZMeta?.name,
    path: "/en/:pathMatch(.*)",
    component: component_45stubzIw5vSZPrZ
  },
  {
    name: component_45stubzIw5vSZPrZMeta?.name,
    path: "/fr/:pathMatch(.*)",
    component: component_45stubzIw5vSZPrZ
  },
  {
    name: component_45stubzIw5vSZPrZMeta?.name,
    path: "/pt-BR/:pathMatch(.*)",
    component: component_45stubzIw5vSZPrZ
  }
]